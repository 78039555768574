import { query } from "@solidjs/router";
import { MapboxAPIResponse } from "./types";

export const getLocationSuggestions = async (query: string) => {
  const params = new URLSearchParams();
  params.set("q", query);
  params.set("proximity", "ip");
  params.set("access_token", import.meta.env.VITE_MAPBOX_ACCESS_TOKEN);

  const response = await fetch(
    `https://api.mapbox.com/search/geocode/v6/forward?${params}`
  );

  if (!response.ok) {
    // TODO
    throw new Error("Mapbox error");
  }

  const data: MapboxAPIResponse = await response.json();
  return data;
};

export const useLocationSuggestions = query(
  async (query: string) => getLocationSuggestions(query),
  "location-suggestions"
);
